var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { display: "flex", "justify-content": "space-between" } },
    [
      _c(
        "div",
        { staticStyle: { width: "49.5%" } },
        [
          _c(
            "el-card",
            [
              _c("head-layout", { attrs: { "head-title": "源数据差异" } }),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%", "margin-top": "10px" },
                  attrs: {
                    data: _vm.tableData,
                    size: "samll",
                    border: "",
                    height: "650",
                  },
                },
                _vm._l(_vm.tableOptions, function (item, index) {
                  return _c("el-table-column", {
                    key: index,
                    attrs: { prop: item, label: item, width: "120" },
                  })
                }),
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    float: "right",
                    "margin-top": "10px",
                    "margin-bottom": "10px",
                  },
                },
                [
                  _c("el-pagination", {
                    staticClass: "paging",
                    attrs: {
                      currentPage: _vm.page.currentPage,
                      "page-sizes": [10, 50, 100],
                      "page-size": _vm.page.pageSize,
                      layout: "total,sizes, prev, pager, next",
                      total: _vm.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { width: "49.5%" } },
        [
          _c(
            "el-card",
            [
              _c("head-layout", { attrs: { "head-title": "目标数据差异" } }),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%", "margin-top": "10px" },
                  attrs: {
                    data: _vm.tableData2,
                    size: "samll",
                    border: "",
                    height: "650",
                  },
                },
                _vm._l(_vm.tableOptions2, function (item, index) {
                  return _c("el-table-column", {
                    key: index,
                    attrs: { prop: item, label: item, width: "120" },
                  })
                }),
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    float: "right",
                    "margin-top": "10px",
                    "margin-bottom": "10px",
                  },
                },
                [
                  _c("el-pagination", {
                    staticClass: "paging",
                    attrs: {
                      currentPage: _vm.page2.currentPage,
                      "page-sizes": [10, 50, 100],
                      "page-size": _vm.page2.pageSize,
                      layout: "total,sizes, prev, pager, next",
                      total: _vm.total2,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange2,
                      "current-change": _vm.handleCurrentChange2,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }